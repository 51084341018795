<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">

    <vs-alert color="danger" title="User Not Found" :active.sync="resource_not_found">
      <span>Resource record with id: {{ $route.params.resourceId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'all-resource-list'}" class="text-inherit underline">All Resources</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="resource_data">

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Account" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <user-edit-tab-account class="mt-4" :data="resource_data" />
            </div>
          </vs-tab>
          <vs-tab label="Information" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <user-edit-tab-information class="mt-4" :data="resource_data" />
            </div>
          </vs-tab>
          <!-- vs-tab label="Social" icon-pack="feather" icon="icon-share-2">
            <div class="tab-text">
              <user-edit-tab-social class="mt-4" :data="user_data" />
            </div>
          </vs-tab -->
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabSocial      from "./UserEditTabSocial.vue"

// Store Module
import moduleResource from '@/store/resource/moduleResource.js'

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  data() {
    return {
      resource_data: null,
      resource_not_found: false,

      /*
        This property is created for fetching latest data from API when tab is changed

        Please check it's watcher
      */
      activeTab: 0,
    }
  },
  watch: {
    activeTab() {
      this.fetch_resource_data(this.$route.params.resourceId)
    }
  },
  methods: {
    fetch_resource_data(resourceId) {
      this.$store.dispatch("resource/fetchOneResource", resourceId)
        .then(res => { this.resource_data = res.data })
        .catch(err => {
          if(err.response.status === 404) {
            this.resource_not_found = true
            return
          }

          if(err.response.status === 500) {
            this.resource_not_found = true
            return
          }
          console.error(err) })
    }
  },
  created() {
    // Register Module UserManagement Module
    if(!moduleResource.isRegistered) {
      this.$store.registerModule('moduleResource', moduleResource)
      moduleResource.isRegistered = true
    }
    this.fetch_resource_data(this.$route.params.resourceId)
  }
}

</script>
